import { emailIsSuperAppAdmin } from "@/common/src/utils/email";

export const isProdEnv = () => {
  return process.env.NEXT_PUBLIC_STAGE ? process.env.NEXT_PUBLIC_STAGE.startsWith("prod") : process.env.NODE_ENV.startsWith("prod");
};

export const isTestEnv = () => {
  return process.env.NEXT_PUBLIC_STAGE ? process.env.NEXT_PUBLIC_STAGE.startsWith("test") : process.env.NODE_ENV.startsWith("test");
};

export const isDevEnv = () => {
  return process.env.NEXT_PUBLIC_STAGE ? process.env.NEXT_PUBLIC_STAGE.startsWith("dev") : process.env.NODE_ENV.startsWith("dev");
};

export const isTestOrDevEnv = () => {
  return isTestEnv() || isDevEnv();
};

export const isTestDevOrSuperAppAdmin = (email?: string) => {
  return isTestOrDevEnv() || emailIsSuperAppAdmin(email);
};